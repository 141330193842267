import React, { JSX } from "react";
import Modal from "@nib-components/modal";
import Copy from "@nib-components/copy";
import { Margin } from "styled-components-spacing";

import { AppConfiguration, useConfig } from "../ConfigProvider";
import { ContactPhoneNumberDisplay } from "../ContactUs";
import { LinkButton } from "../components";
import { TealiumTrack, useTealiumWrapper } from "../util/TealiumWrapper";
import { BaseConfigForLanguage, componentConstants } from "./constants";

export type WheresMyCodeProps = {
  onClose?: () => void;
  showHelp: boolean;
};

const generateHelpHeader = (constants: BaseConfigForLanguage) => {
  return (
    <>
      <Margin bottom={1}>
        <Copy align="left">{constants.NEED_MORE_HELP}</Copy>
      </Margin>
    </>
  );
};

const generateTravelAgentContent = (
  title: string,
  constants: BaseConfigForLanguage,
): JSX.Element => {
  return (
    <>
      {generateHelpHeader(constants)}
      <ul>
        <li>
          <Copy>{title}</Copy>
        </li>
      </ul>
      {ContactPhoneNumberDisplay(true, false)}
    </>
  );
};

const generateNeedMoreHelpElement = (
  config: AppConfiguration,
  constants: BaseConfigForLanguage,
  tealiumTrack: TealiumTrack,
) => {
  const clickHandler = () => {
    tealiumTrack("need_more_help_click", {});
    window.location.href = config.needHelpUrl;
  };

  switch (config.brand) {
    case "iap":
      return (
        <>
          {generateHelpHeader(constants)}
          <ul>
            <li>
              <Copy>{constants.IAP_NEED_MORE_HELP}</Copy>
            </li>
          </ul>
        </>
      );
    case "nibau":
      return generateTravelAgentContent(constants.NIBAU_NEED_MORE_HELP, constants);
    case "ns":
    case "cota":
    case "qan":
      return generateTravelAgentContent(constants.CONTACT_NIB, constants);
    default:
      return (
        <LinkButton onClick={clickHandler} target="_blank" rel="noopener">
          {constants.NEED_MORE_HELP}
        </LinkButton>
      );
  }
};

export const WheresMyCode = ({ onClose, showHelp }: WheresMyCodeProps): JSX.Element => {
  const config = useConfig();
  const { tealiumTrack } = useTealiumWrapper();

  const constants = componentConstants(config);

  const showNeedMoreHelpLink = config.brand !== "nib-health-services";

  return (
    <Modal
      title="Having trouble logging in?"
      visible={showHelp}
      onClose={onClose}
      data-testid="help-component"
    >
      {constants.COMMON_ISSUES_HEADING && (
        <Margin bottom={3}>
          <Copy align="left">{constants.COMMON_ISSUES_HEADING}</Copy>
        </Margin>
      )}
      {constants.COMMON_ISSUES && (
        <Margin bottom={3}>
          <ul>
            {Object.keys(constants.COMMON_ISSUES).map((key, index) => {
              return (
                <li key={index}>
                  <Copy>{constants.COMMON_ISSUES[key]}</Copy>
                </li>
              );
            })}
          </ul>
        </Margin>
      )}
      {showNeedMoreHelpLink && (
        <Margin bottom={2}>{generateNeedMoreHelpElement(config, constants, tealiumTrack)}</Margin>
      )}
    </Modal>
  );
};
