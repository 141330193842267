import React, { JSX } from "react";
import styled from "styled-components";
import { Margin, py, px } from "styled-components-spacing";
import Heading from "@nib-components/heading";
import breakpoint from "styled-components-breakpoint";
import { useConfig } from "../ConfigProvider";

const HeaderEl = styled.header`
  ${py(6)};
  ${px(5)};
  // On subsequent screen email is displayed in the header, we want to kepp form postion the same
  min-height: 130px;

  ${breakpoint("md")`
    min-height: 0px;
  `}
`;

type HeaderProps = {
  title?: string;
  children: JSX.Element | JSX.Element[];
};

const Header = ({ title, children }: HeaderProps): JSX.Element => {
  const config = useConfig();

  return (
    <HeaderEl data-testid="header-component">
      <Margin bottom={5}>
        <Heading
          align="center"
          size={config.brand === "nib" ? 2 : 1}
          data-testid="header-component-text"
          component="h1"
        >
          {title}
        </Heading>
      </Margin>
      {children}
    </HeaderEl>
  );
};

export default Header;
