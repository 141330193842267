import React from "react";
import { AlertLevel } from "../util/servicesNotificationApi";
import Alert from "@nib-components/alert";
import { Box } from "@nib/layout";
import Overlay from "@nib-components/overlay";
import { useServicesNotification } from "../util/ServicesNotificationProvider";
import { typeValues as AlertType } from "@nib-components/alert/dist/@types/types";

const toAlertType = (type: AlertLevel): AlertType => {
  switch (type) {
    case "allServicesOutage":
      return "error";
    case "serviceOutage":
      return "warning";
    case "warning":
      return "warning";
    case "notice":
      return "info";
    case "info":
      return "info";
    default:
      return "info";
  }
};

export default function ServicesNotificationComponent() {
  const servicesNotification = useServicesNotification();

  if (!servicesNotification) {
    return null;
  }

  // allServicesOutage blocks the user from performing any action
  if (servicesNotification.level === "allServicesOutage") {
    return (
      <Overlay visible>
        <Box display="flex" justifyContent="center" alignItems="center" padding={4}>
          <Box maxWidth="800px" data-testid="services-notification-component">
            <Alert type="error" title={servicesNotification.title}>
              <div data-testid="services-notification-component-content">
                {servicesNotification.details}
              </div>
            </Alert>
          </Box>
        </Box>
      </Overlay>
    );
  }

  return (
    <Box paddingHorizontal={5} marginBottom={5} data-testid="services-notification-component">
      <Alert
        fullWidth
        type={toAlertType(servicesNotification.level)}
        title={servicesNotification.title}
      >
        <div data-testid="services-notification-component-content">
          {servicesNotification.details}
        </div>
      </Alert>
    </Box>
  );
}
