export enum ContactType {
  NONE = "NO-CONTACT-TYPE",
  EMAIL = "EMAIL-CONTACT-TYPE",
  PHONE = "PHONE-CONTACT-TYPE",
}

export type MemberNumberState = {
  memberNumber: string;
  isValid: boolean;
  hasBeenValidated: boolean;
  validationMessage: string;
};

export type LoginState = {
  contact: string;
  rememberContact: boolean;
  previousContactType: ContactType;
  isValid: boolean;
  hasBeenValidated: boolean;
  validationMessage: string;
  contactSendingInProgress: boolean;
  lastPasswordlessStartSuccessResultTime?: number;
  alert: {
    type?: string;
    message?: string;
    title?: string;
  };
  displayMemberNumberForm: boolean;
  displayMemberNumberStates: string[];
  memberNumber: MemberNumberState;
};

export type OTPState = {
  code: string;
  isValid: boolean;
  hasBeenValidated: boolean;
  validationMessage: string;
  verifying: boolean;
  alert: {
    type?: string;
    message?: string;
    title?: string;
  };
};

export type State = {
  login: {
    login: LoginState;
    otp: OTPState;
  };
  contactUsFlow: {
    showComponent: boolean;
  };
};

export const initialState: State = {
  login: {
    login: {
      contact: "",
      rememberContact: false,
      previousContactType: ContactType.NONE,
      isValid: false,
      hasBeenValidated: false,
      validationMessage: "",
      contactSendingInProgress: false,
      lastPasswordlessStartSuccessResultTime: undefined,
      alert: {},
      displayMemberNumberForm: false,
      displayMemberNumberStates: [],
      memberNumber: {
        memberNumber: "",
        isValid: false,
        hasBeenValidated: false,
        validationMessage: "",
      },
    },
    otp: {
      code: "",
      isValid: false,
      hasBeenValidated: false,
      validationMessage: "",
      verifying: false,
      alert: {},
    },
  },
  contactUsFlow: {
    showComponent: false,
  },
};
