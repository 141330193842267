import Copy from "@nib-components/copy";
import { Margin, Stack } from "@nib/layout";
import { History } from "history";
import React, { useEffect, useState, JSX } from "react";
import { useConfig } from "../ConfigProvider";
import { useStateValue } from "../StateManagement";
import { WheresMyCode } from "../WheresMyCodeModal";
import {
  AlertComponent,
  CheckboxComponent,
  CopyElement,
  DisplayBorder,
  Header,
  LinkButton,
  NoBorderForm,
  NoSpacingPrimaryButton,
  TextBoxComponent,
} from "../components";
import { useOptimizely } from "../optimizely";
import { useTealiumWrapper } from "../util/TealiumWrapper";
import MemberNumberInput from "./MemberNumberInput";
import { useActionCreator } from "./actions/loginActionCreator";
import { passwordlessFlowConstants } from "./dataConstants";
import PhoneNumberInfoBox from "./PhoneNumberInfoBox";
import { shouldShowPhoneNumberInfoBox } from "./PhoneNumberInfoBox/shouldShowPhoneNumberInfoBox";
import ServicesNotificationComponent from "../components/ServicesNotificationComponent";

type LoginProps = {
  history?: History;
  isDefaultLogin?: boolean;
};

const Login = ({ history, isDefaultLogin = true }: LoginProps): JSX.Element => {
  const action = useActionCreator();
  const loginState = useStateValue().state.login.login;
  const config = useConfig();
  const constants = passwordlessFlowConstants(config);
  const [showHelp, setShowHelp] = useState(false);
  const { tealiumTrack, tealiumPageView } = useTealiumWrapper();

  useEffect(() => {
    tealiumPageView({
      channel: "Online",
      site_name: process.env.REACT_APP_AUTH_DOMAIN || "id.guhealth.com.au",
      site_section: "online_services",
      page_name: "auth0-login-pages",
      page_type: "online_services",
    });
  }, [tealiumPageView]);

  const displayHelpModal = () => {
    tealiumTrack("display_help_modal", {});
    setShowHelp(true);
  };

  const { state } = useStateValue();

  useOptimizely(config?.optimizelyUrl);

  useEffect(() => {
    if (shouldShowPhoneNumberInfoBox(state, config) && loginState.rememberContact) {
      action.rememberContactChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState.contact]);

  return (
    <>
      <WheresMyCode
        {...{
          showHelp,
          onClose: () => setShowHelp(false),
        }}
      />
      {isDefaultLogin ? (
        <>
          <Header title={constants.ENTER_CONTACT_DETAILS_HEADER_TITLE}>
            {loginState.alert.type ? (
              <AlertComponent
                title={loginState.alert.title}
                message={loginState.alert.message}
                type={loginState.alert.type}
              />
            ) : (
              <CopyElement align="center">
                {constants.ENTER_CONTACT_DETAILS_HEADER_SUBTEXT}
              </CopyElement>
            )}
          </Header>
          <ServicesNotificationComponent />
        </>
      ) : null}
      <DisplayBorder requireShadow={isDefaultLogin}>
        <NoBorderForm
          data-testid="contact-form"
          id="contactForm"
          name="contactForm"
          onSubmit={() => action.submitForm(history as History, config?.brand)}
        >
          <TextBoxComponent
            id="contact"
            name="contact"
            valid={loginState.isValid}
            validated={loginState.hasBeenValidated}
            label={constants.ENTER_CONTACT_DETAILS_TEXTBOX_LABEL}
            error={loginState.validationMessage}
            dataTestid="contact-input"
            type="email"
            title="Login"
            disabled={loginState.contactSendingInProgress}
            onChange={(event: any) => action.contactChanged(event.target.value)}
            onBlur={() => action.validateForm()}
            autoFocus={true}
            value={loginState.contact}
          />

          {shouldShowPhoneNumberInfoBox(state, config) && <PhoneNumberInfoBox />}

          <MemberNumberInput />

          <CheckboxComponent
            id="rememberContact"
            name="rememberContact"
            label={constants.REMEMBER_CONTACT_DETAILS_CHECKBOX_LABEL}
            text={constants.REMEMBER_CONTACT_DETAILS_CHECKBOX_TEXT}
            dataTestid="remember-contact-checkbox"
            disabled={
              loginState.contactSendingInProgress || shouldShowPhoneNumberInfoBox(state, config)
            }
            onChange={({ target }) => action.rememberContactChanged(target.checked)}
            checked={loginState.rememberContact}
          />

          <Stack space={4}>
            <NoSpacingPrimaryButton
              fullWidth
              type="submit"
              data-id={`ga-inlink-login-with-member-contact-next${
                loginState.contactSendingInProgress ? "-spinner" : ""
              }`}
              isLoading={loginState.contactSendingInProgress}
              disabled={loginState.contactSendingInProgress}
            >
              {loginState.contactSendingInProgress
                ? constants.ENTER_CONTACT_DETAILS_SUBMIT_BUTTON_LOADING_TEXT
                : constants.SUBMIT_BUTTON_TEXT}
            </NoSpacingPrimaryButton>

            <Copy align="center" small>
              <LinkButton onClick={displayHelpModal} type="button">
                Are you having trouble logging in?
              </LinkButton>
            </Copy>
          </Stack>
        </NoBorderForm>
      </DisplayBorder>
      <Margin bottom={5} />
    </>
  );
};

export default Login;
