import React, { JSX } from "react";
import Copy from "@nib-components/copy";
import Heading from "@nib-components/heading";
import Link from "@nib-components/link";
import { Box, Inline, Stack, Hidden } from "@nib/layout";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { panelConstants } from "./dataConstants";

const AppLink = styled(Link)`
  display: inline-block;
  box-shadow: none;

  ${breakpoint("md")`
    height: 52px;
  `}

  height: 42px;
`;

const BoxWithBackground = styled(Box)`
  display: flex;
  align-items: center;
  ${breakpoint("md")`
  background-image: ${(props) => `url("${props.assetBaseUrl}my_cover.png")`};
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: 92% 50%;`}
`;

const PaddedBox = styled(Box)`
  ${breakpoint("md")`
    padding-right: 250px;
  `}

  padding-right: 0px;
`;

const ClickThroughImage = styled.img`
  pointer-events: none;
`;

type NibAppPanelProps = {
  assetBaseUrl: string;
};

export function NibAppPanel({ assetBaseUrl }: NibAppPanelProps): JSX.Element {
  const constants = panelConstants();

  return (
    <BoxWithBackground
      borderRadius={"standard"}
      padding={{ xs: 6, md: 7 }}
      paddingTop={{ xs: 0, md: 7 }}
      paddingRight={{ xs: 6, lg: 0 }}
      background="white"
      width="100%"
      assetBaseUrl={assetBaseUrl}
    >
      <PaddedBox>
        <Stack space={{ xs: 3, md: 6 }}>
          <Hidden below="md">
            <Heading size={3}>{constants.NIB_APP_PANEL_HEADING}</Heading>
          </Hidden>

          <Hidden above="md">
            <Heading size={4}>{constants.NIB_APP_PANEL_HEADING_SMALL}</Heading>
          </Hidden>
          <Copy>{constants.NIB_APP_PANEL_COPY}</Copy>

          <Inline space={{ xs: 2, md: 4 }}>
            <AppLink
              data-id="ga-inlink-login-app-panel-apple"
              href="https://apps.apple.com/au/app/nib-health-insurance/id373883175"
            >
              <ClickThroughImage
                src={`${assetBaseUrl}/app-store-badge.svg`}
                alt="Apple App Store badge"
                height="100%"
              />
            </AppLink>

            <AppLink
              data-id="ga-inlink-login-app-panel-google"
              href="https://play.google.com/store/apps/details?id=au.com.nib"
            >
              <ClickThroughImage
                src={`${assetBaseUrl}/google-store-badge.png`}
                alt="Android App Store badge"
                height="100%"
              />
            </AppLink>
          </Inline>
        </Stack>
      </PaddedBox>
    </BoxWithBackground>
  );
}
