import React from "react";
import { useStateValue } from "../StateManagement";
import { useActionCreator } from "./actions/loginActionCreator";
import { TextBoxComponent } from "../components";
import { useConfig } from "../ConfigProvider";
import { passwordlessFlowConstants } from "./dataConstants";
import { skipMemberNumberCheck } from "../util/contactUtilities";

const MemberNumberInput = () => {
  const { state } = useStateValue();
  const config = useConfig();
  const action = useActionCreator();
  const constants = passwordlessFlowConstants(config);

  const memberNumberState = state.login.login.memberNumber;

  const shouldShowInput = () => {
    const phoneFeature = config.featureFlags.enablePhoneNumberAuth;

    if (phoneFeature.isOff() || skipMemberNumberCheck(config?.brand)) {
      return false;
    }

    return state.login.login.displayMemberNumberForm;
  };

  const helpText = constants?.ENTER_MEMBER_HELP_TEXT
    ? constants.ENTER_MEMBER_HELP_TEXT(config.brandName)
    : undefined;

  return shouldShowInput() ? (
    <TextBoxComponent
      id="memberNumber"
      name="memberNumber"
      valid={memberNumberState?.isValid}
      validated={memberNumberState?.hasBeenValidated}
      label={constants.ENTER_MEMBER_NUMBER_TEXTBOX_LABEL}
      help={helpText}
      error={memberNumberState?.validationMessage}
      type="text"
      title="Member number"
      pattern="\d*"
      disabled={state.login.login.contactSendingInProgress}
      onChange={(e: any) => action.memberNumberChanged(e.target.value)}
      onBlur={() => action.validateMemberNumberInput()}
      autoFocus={false}
      value={memberNumberState?.memberNumber}
    />
  ) : null;
};

export default MemberNumberInput;
