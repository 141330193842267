import React, { JSX, createContext, useContext } from "react";
import { appForClient } from "./util/appMapping";
import qs from "querystring";
import { brandNameForBrand, brandDomainsForBrand } from "./util/brandMappings";
import { EnablePhoneNumberAuthFeatureFlag } from "./util/featureFlagUtils";
import {
  GetServicesNotificationFunction,
  getServicesNotificationApiFunction,
} from "./util/servicesNotificationApi";

export type AppConfiguration = {
  domain: string;
  clientID: string;
  responseType?: string;
  redirectUri?: string;
  scope?: string;
  audience?: string;
  state?: string;
  tenant?: string;
  nonce?: string;
  contactUsUrl: string;
  language: string;
  brand: string;
  currentApp?: string;
  brandName: string;
  needHelpUrl: string;
  isProd: boolean;
  assetBaseUrl: string;
  featureFlags: {
    enablePhoneNumberAuth: EnablePhoneNumberAuthFeatureFlag;
  };
  isSingleSignOn: boolean;
  optimizelyUrl?: string;
  getServicesNotification: GetServicesNotificationFunction;
};

const getQueryParams = (searchQuery) => {
  if (searchQuery[0] === "?") {
    searchQuery = searchQuery.slice(1, searchQuery.length);
  }

  if (searchQuery[searchQuery.length - 1] === "/") {
    searchQuery = searchQuery.slice(0, -1);
  }

  const queryParams = qs.parse(searchQuery);

  return {
    nonce: queryParams.nonce as string,
    state: queryParams.state as string,
    redirectUri: queryParams.redirect_uri as string,
    clientID: Array.isArray(queryParams.client)
      ? queryParams.client[0]
      : (queryParams.client as string),
    audience: queryParams.audience as string,
    scope: queryParams.scope as string,
    responseType: queryParams.response_type as string,
    handOffNonce: queryParams.handOffNonce as string,
  };
};

export const getAppConfiguration = ({
  brand = "nib",
  tenant = "no_tenant_provided",
  domain,
  stage = "kaos",
  enablePhoneNumberAuth = "off",
  assetBaseUrl,
  searchQuery = window.location.search,
  optimizelyUrl,
  configApiUrl,
}) => {
  const { nonce, state, redirectUri, clientID, audience, scope, responseType, handOffNonce } =
    getQueryParams(searchQuery);
  const needHelpUrl =
    brand === "nib"
      ? `https://${brandDomainsForBrand(brand, stage)}/help/health?tabIndex=Account`
      : `https://${brandDomainsForBrand(brand, stage)}/need-some-help`;

  const appConfiguration: AppConfiguration = {
    tenant,
    nonce,
    state,
    redirectUri,
    clientID,
    audience,
    domain,
    scope,
    responseType,
    contactUsUrl: "https://www.guhealth.com.au/contact-us",
    language: "english",
    brand,
    currentApp: appForClient(stage, clientID),
    brandName: brandNameForBrand(brand),
    needHelpUrl,
    isProd: stage === "prod",
    assetBaseUrl,
    featureFlags: {
      enablePhoneNumberAuth: new EnablePhoneNumberAuthFeatureFlag(enablePhoneNumberAuth),
    },
    isSingleSignOn: handOffNonce ? true : false,
    optimizelyUrl,
    getServicesNotification: getServicesNotificationApiFunction(configApiUrl),
  };

  return appConfiguration;
};

const processConfig = {
  brand: process.env.REACT_APP_BRAND,
  tenant: process.env.REACT_APP_TENANT,
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  stage: process.env.REACT_APP_STAGE,
  enablePhoneNumberAuth: process.env.REACT_APP_ENABLE_PHONE_NUMBER_AUTH,
  assetBaseUrl: process.env.REACT_APP_ASSET_BASE_URL,
  optimizelyUrl: process.env.REACT_APP_OPTIMIZELY_URL,
  configApiUrl: process.env.REACT_APP_CONFIG_API_URL,
};

export const ConfigContext = createContext<AppConfiguration>(getAppConfiguration(processConfig));

export const ConfigProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => (
  <ConfigContext.Provider value={getAppConfiguration(processConfig)}>
    {children}
  </ConfigContext.Provider>
);

export const useConfig = (): AppConfiguration => useContext(ConfigContext);
