import { isEmailOnlyBrand } from "../util/brandMappings";
import { EnablePhoneNumberAuthFeatureFlag } from "../util/featureFlagUtils";

export type BaseConfigForLanguage = {
  COMMON_ISSUES_HEADING: string;
  COMMON_ISSUES: {
    CORRECT_DETAILS: string;
    CODE_IN_SPAM?: string | undefined;
    RECENT_CODE: string;
  };

  NEED_MORE_HELP: string;
  IAP_NEED_MORE_HELP: string;
  NIBAU_NEED_MORE_HELP: string;
  CONTACT_NIB: string;
};

export const componentConstants = ({
  language,
  featureFlags,
  brand,
}: {
  language: string;
  featureFlags: { enablePhoneNumberAuth: EnablePhoneNumberAuthFeatureFlag };
  brand: string;
}): BaseConfigForLanguage => {
  return baseConfigForLanguage(language, featureFlags.enablePhoneNumberAuth, brand);
};

const baseConfigForLanguage = (
  language: string,
  enablePhoneNumberAuth: EnablePhoneNumberAuthFeatureFlag,
  brand: string,
): BaseConfigForLanguage => {
  const commonIssues =
    !enablePhoneNumberAuth.isOn() || isEmailOnlyBrand(brand)
      ? {
          CORRECT_DETAILS: "Did you type your email address correctly?",
          CODE_IN_SPAM: "Did your verification code go to your junk or spam folder?",
          RECENT_CODE: "Are you using the most recent verification code you received?",
        }
      : {
          CORRECT_DETAILS: "Did you type your mobile number or email address correctly?",
          RECENT_CODE: "Are you using the most recent verification code you received?",
        };

  switch (language) {
    default:
    case "english": {
      return {
        COMMON_ISSUES_HEADING: "Here are a few common issues to check...",
        COMMON_ISSUES: commonIssues,
        NEED_MORE_HELP: "Need more help?",
        IAP_NEED_MORE_HELP: "Please contact your account manager for assistance",
        NIBAU_NEED_MORE_HELP: "Please contact us for assistance",
        CONTACT_NIB: "Please contact nib:",
      };
    }
  }
};
