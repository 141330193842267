import React, { JSX } from "react";

import { Margin } from "styled-components-spacing";
import Grid from "styled-components-grid";
import Copy, { Bold } from "@nib-components/copy";
import Link from "@nib-components/link";

import { contactUsConstants } from "./dataConstants";
import { useConfig } from "../ConfigProvider";

export const ContactPhoneNumberDisplay = (
  list: boolean = false,
  titles: boolean = true,
): JSX.Element => {
  const config = useConfig();
  const constants = contactUsConstants(config?.language, config?.brand);
  return (
    <React.Fragment>
      {list ? (
        <ul style={{ listStyleType: "none" }}>
          <li>
            <Grid>
              {titles && (
                <Grid.Unit size={0.5}>
                  <Copy>
                    <Bold>{constants.contactUsDomesticText}</Bold>
                  </Copy>
                </Grid.Unit>
              )}
              <Grid.Unit size={0.5}>
                <Link
                  id="ga-inlink-login-with-member-contact-us-inside-australia"
                  href={constants.contactUsDomesticNumber}
                >
                  {constants.domesticNumberText}
                </Link>
              </Grid.Unit>
            </Grid>
          </li>
          <li>
            <Margin top={5}>
              <Grid>
                {titles && (
                  <Grid.Unit size={0.5}>
                    <Copy>
                      <Bold>{constants.contactUsInternationalText}</Bold>
                    </Copy>
                  </Grid.Unit>
                )}
                <Grid.Unit size={0.5}>
                  <Link
                    id="ga-inlink-login-with-member-contact-us-outside-australia"
                    href={constants.contactUSInternationalNumber}
                  >
                    {constants.internationalNumberText}
                  </Link>
                </Grid.Unit>
              </Grid>
            </Margin>
          </li>
        </ul>
      ) : (
        <>
          <Grid>
            <Grid.Unit size={0.5}>
              <Copy>
                <Bold>{constants.contactUsDomesticText}</Bold>
              </Copy>
            </Grid.Unit>
            <Grid.Unit size={0.5}>
              <Link
                id="ga-inlink-login-with-member-contact-us-inside-australia"
                href={constants.contactUsDomesticNumber}
              >
                {constants.domesticNumberText}
              </Link>
            </Grid.Unit>
          </Grid>
          <Margin top={5}>
            <Grid>
              <Grid.Unit size={0.5}>
                <Copy>
                  <Bold>{constants.contactUsInternationalText}</Bold>
                </Copy>
              </Grid.Unit>
              <Grid.Unit size={0.5}>
                <Link
                  id="ga-inlink-login-with-member-contact-us-outside-australia"
                  href={constants.contactUSInternationalNumber}
                >
                  {constants.internationalNumberText}
                </Link>
              </Grid.Unit>
            </Grid>
          </Margin>
        </>
      )}
    </React.Fragment>
  );
};
