const kaos = "KAOS";
const prod = "PROD";
export const appForClient = (stage: string = "KAOS", clientId: string = ""): string | undefined => {
  const app = apps.find(
    (x) => x.stage === stage.toUpperCase().trim() && x.clientId === clientId.trim(),
  );
  return app ? app.appName : undefined;
};

export const appNames = {
  nibOnlineServicesWeb: "nibOnlineServicesWeb",
  nibOnlineServicesWebTest: "nibOnlineServicesWebTest",
  nibIdPrototype: "nibIdPrototype",
  nibIdPrototypeTest: "nibIdPrototypeTest",
  apiaOnlineServicesWeb: "apiaOnlineServicesWeb",
  suncorpOnlineServicesWeb: "suncorpOnlineServicesWeb",
};

const apps = [
  {
    stage: kaos,
    appName: appNames.nibOnlineServicesWeb,
    clientId: "V1VC0ZttnpII2e1SU2jmWqc6wftcnvcl",
  },
  {
    stage: kaos,
    appName: appNames.nibIdPrototype,
    clientId: "F47UrQ34RNVsXmokEXKJLCaOdPM6j5iy",
  },
  {
    stage: kaos,
    appName: appNames.nibIdPrototypeTest,
    clientId: "fSJutI5Bdwvib6eMnYzid3hWoDl4kbca",
  },
  {
    stage: kaos,
    appName: appNames.nibOnlineServicesWebTest,
    clientId: "fd6Im10KA7A3V6IJm1bk64YTOwJ23og9",
  },
  {
    stage: kaos,
    appName: appNames.apiaOnlineServicesWeb,
    clientId: "NO4BCaqRtp5k2S268xrOHuES5vtMm7qv",
  },
  {
    stage: kaos,
    appName: appNames.suncorpOnlineServicesWeb,
    clientId: "KYOqWxDsh4DSbg7K3GbNUq6wQwlzZ7VB",
  },
  {
    stage: prod,
    appName: appNames.nibOnlineServicesWeb,
    clientId: "bEmn1GzPj5Lq9gsBXcRW2QBnVAAznGVG",
  },
  {
    stage: prod,
    appName: appNames.apiaOnlineServicesWeb,
    clientId: "03dDlS3Vbpk4QrBHSQcpbJw6VodKrH93",
  },
  {
    stage: prod,
    appName: appNames.suncorpOnlineServicesWeb,
    clientId: "yxXVO30qd3wdS3qx5sk9eXysvdTp0OyT",
  },
];
