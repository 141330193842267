import React, { JSX } from "react";
import { AlertComponent, Header, CopyElement } from "../components";
import { useConfig } from "../ConfigProvider";
import { passwordlessFlowConstants } from "../Login/dataConstants";
import { useStateValue } from "../StateManagement";

export function HeaderPanel(): JSX.Element {
  const { state } = useStateValue();
  const config = useConfig();
  const constants = passwordlessFlowConstants(config);

  return (
    <Header title={constants.ENTER_CONTACT_DETAILS_HEADER_TITLE}>
      {state.login.login.alert.type ? (
        <AlertComponent
          title={state.login.login.alert.title}
          message={state.login.login.alert.message}
          type={state.login.login.alert.type}
        />
      ) : (
        <CopyElement align="center">{constants.ENTER_CONTACT_DETAILS_HEADER_SUBTEXT}</CopyElement>
      )}
    </Header>
  );
}
