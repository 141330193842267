import breakpoint from "styled-components-breakpoint";
import styled from "styled-components";
import Copy from "@nib-components/copy";

const CopyElement = styled(Copy)`
  ${breakpoint("md")`
    font-size: 1.125rem;
  `}
`;

export default CopyElement;
