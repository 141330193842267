import * as actionTypes from "../actions/actionTypes";
import { dateNow } from "../../util/dateUtils";
import { memberNumberDisplayStates } from "../constants";
import { ContactType, LoginState, MemberNumberState, OTPState } from "../../initialState";
import { BaseAction } from "../../StateManagement/reducer";

export interface LoginAction extends BaseAction {
  contact: string;
  rememberContact: boolean;
  isValid: boolean;
  displayMemberNumberForm: boolean;
  hasBeenValidated: boolean;
  validationMessage: string;
  alertType: string;
  title: string;
  message: string;
  memberNumberState: MemberNumberState;
  memberNumber: string;
  previousType: ContactType;
}

export interface OTPAction extends BaseAction {
  code: string;
  isValid: boolean;
  hasBeenValidated: boolean;
  validationMessage: string;
  alertType: string;
  title: string;
  message: string;
}

function shouldAddState(currentState: boolean, newState: boolean, stateArray: string[]): string[] {
  if (currentState !== newState) {
    const modArray = [
      ...stateArray,
      newState ? memberNumberDisplayStates.ON : memberNumberDisplayStates.OFF,
    ];
    return modArray;
  }

  return stateArray;
}

const contactLoginReducer = (state: LoginState, action: LoginAction): LoginState => {
  switch (action.type) {
    case actionTypes.UPDATE_CONTACT:
      return {
        ...state,
        contact: action.contact,
        isValid: action.isValid,
        hasBeenValidated: false,
        lastPasswordlessStartSuccessResultTime: undefined,
        displayMemberNumberForm: action.displayMemberNumberForm,
        displayMemberNumberStates: shouldAddState(
          state.displayMemberNumberForm,
          action.displayMemberNumberForm,
          state.displayMemberNumberStates,
        ),
      };
    case actionTypes.UPDATE_REMEMBER_CONTACT:
      return {
        ...state,
        rememberContact: action.rememberContact,
        // This is required to reset the 'don't send again within 5 mins' otp limit imposed by shouldPasswordlessCallBeSkipped(..).
        // Normally when login details change it is reset but when the rememberContact checkbox
        // is unchecked no login details are changed and so the otp time limit is not reset.
        lastPasswordlessStartSuccessResultTime: undefined,
      };
    case actionTypes.VALIDATE_FORM:
      return {
        ...state,
        hasBeenValidated: action.hasBeenValidated,
        isValid: action.isValid,
        validationMessage: action.validationMessage,
      };
    case actionTypes.SHOW_ALERT:
      return {
        ...state,
        contactSendingInProgress: false,
        alert: {
          type: action.alertType,
          title: action.title,
          message: action.message,
        },
      };
    case actionTypes.START_CONTACT_SENDING:
      return {
        ...state,
        memberNumber: action.memberNumberState,
        contactSendingInProgress: true,
      };
    case actionTypes.COMPLETE_CONTACT_SENDING:
      return {
        ...state,
        contactSendingInProgress: false,
        lastPasswordlessStartSuccessResultTime: dateNow(),
      };

    case actionTypes.CLEAR_ALERTS:
      return {
        ...state,
        alert: {},
      };
    case actionTypes.ACCOUNT_BLOCKED:
      return {
        ...state,
        lastPasswordlessStartSuccessResultTime: undefined,
      };

    case actionTypes.UPDATE_MEMBER_NUMBER:
      return {
        ...state,
        memberNumber: {
          ...state.memberNumber,
          memberNumber: action.memberNumber,
          isValid: action.isValid,
          hasBeenValidated: false,
        },
        lastPasswordlessStartSuccessResultTime: undefined,
      };
    case actionTypes.MEMBER_NUMBER_VALIDATE_INPUT:
      return {
        ...state,
        memberNumber: {
          ...state.memberNumber,
          isValid: action.isValid,
          hasBeenValidated: action.hasBeenValidated,
          validationMessage: action.validationMessage,
        },
      };
    case actionTypes.SET_CONTACT_TYPE:
      return {
        ...state,
        previousContactType: action.previousType,
      };

    default:
      return state;
  }
};
const otpReducer = (state: OTPState, action: OTPAction): OTPState => {
  switch (action.type) {
    case actionTypes.UPDATE_CONTACT_CODE:
      return {
        ...state,
        code: action.code,
        isValid: action.isValid,
      };
    case actionTypes.VALIDATE_OTP_FORM:
      return {
        ...state,
        hasBeenValidated: action.hasBeenValidated,
        validationMessage: action.validationMessage,
      };
    case actionTypes.ACCOUNT_BLOCKED:
    case actionTypes.OTP_SHOW_ALERT:
      return {
        ...state,
        verifying: false,
        code: "",
        hasBeenValidated: false,
        alert: {
          type: action.alertType,
          title: action.title,
          message: action.message,
        },
      };
    case actionTypes.OTP_START_VERIFICATION:
      return {
        ...state,
        verifying: true,
      };
    case actionTypes.OTP_COMPLETE_VERIFICATION:
      return {
        ...state,
        verifying: false,
      };
    case actionTypes.WRONG_CODE:
      return {
        ...state,
        hasBeenValidated: true,
        isValid: false,
        validationMessage: action.message,
        verifying: false,
      };
    case actionTypes.CLEAR_ALERTS:
      return {
        ...state,
        alert: {},
      };
    default:
      return state;
  }
};

export const loginReducer = (
  state: { login: LoginState; otp: OTPState },
  action: BaseAction,
): {
  login: LoginState;
  otp: OTPState;
} => {
  return {
    otp: otpReducer(state.otp, action as OTPAction),
    login: contactLoginReducer(state.login, action as LoginAction),
  };
};
