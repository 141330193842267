type EnglishTextConstants = {
  contactUsModalTitle: string;
  contactUsDomesticText: string;
  contactUsInternationalText: string;
  contactUsLinkText: string;
};

const englishTextConstants = (brand?: string): EnglishTextConstants => {
  switch (brand) {
    case "nibnz":
      return {
        //Contact us modal
        contactUsModalTitle: "Contact us",
        contactUsDomesticText: "Inside New Zealand",
        contactUsInternationalText: "Outside New Zealand",

        //Contact us link
        contactUsLinkText: "Contact Us",
      };
    default:
      return {
        //Contact us modal
        contactUsModalTitle: "Contact us",
        contactUsDomesticText: "Inside Australia",
        contactUsInternationalText: "Outside Australia",

        //Contact us link
        contactUsLinkText: "Contact Us",
      };
  }
};

type BrandContactNumbers = {
  contactUsDomesticNumber: string;
  domesticNumberText: string;
  contactUSInternationalNumber: string;
  internationalNumberText: string;
};

const brandContactNumbers = (brand?: string): BrandContactNumbers => {
  switch (brand) {
    case "gu":
      return {
        contactUsDomesticNumber: "tel:1800249966",
        domesticNumberText: "1800 249 966",
        contactUSInternationalNumber: "tel:+61296924438",
        internationalNumberText: "+61 2 9692 4438",
      };
    case "cignagu":
      return {
        contactUsDomesticNumber: "tel:1800124214",
        domesticNumberText: "1800 124 214",
        contactUSInternationalNumber: "tel:+61296924436",
        internationalNumberText: "+61 2 9692 4436",
      };
    case "nibau":
    case "ns":
    case "cota":
    case "qan":
      return {
        contactUsDomesticNumber: "tel:1300410272",
        domesticNumberText: "1300 410 272",
        contactUSInternationalNumber: "tel:+61292343193",
        internationalNumberText: "+61 2 9234 3193",
      };
    case "nibnz":
      return {
        contactUsDomesticNumber: "tel:0800123642",
        domesticNumberText: "0800 123 642",
        contactUSInternationalNumber: "tel:+6499562613",
        internationalNumberText: "+64 9 956 2613",
      };
    case "real":
      return {
        contactUsDomesticNumber: "tel:1300832028",
        domesticNumberText: "1300 832 028",
        contactUSInternationalNumber: "tel:+611300832028",
        internationalNumberText: "+61 1300 832 028",
      };
    case "australianSeniors":
      return {
        contactUsDomesticNumber: "tel:1300029836",
        domesticNumberText: "1300 029 836",
        contactUSInternationalNumber: "tel:+611300029836",
        internationalNumberText: "+61 1300 029 836",
      };
    case "aami":
      return {
        contactUsDomesticNumber: "tel:132244",
        domesticNumberText: "13 22 44",
        contactUSInternationalNumber: "tel:+61385201300",
        internationalNumberText: "+61 3 8520 1300",
      };
    case "nib":
    default:
      return {
        contactUsDomesticNumber: "tel:131642",
        domesticNumberText: "13 16 42",
        contactUSInternationalNumber: "tel:+61296924300",
        internationalNumberText: "+61 2 9692 4300",
      };
  }
};

type ContactUsConstants = EnglishTextConstants & BrandContactNumbers;

export const contactUsConstants = (language: string, brand: string): ContactUsConstants => {
  switch (language) {
    case "english":
      return {
        ...englishTextConstants(brand),
        ...brandContactNumbers(brand),
      };
    default:
      return {
        ...englishTextConstants(brand),
        ...brandContactNumbers(brand),
      };
  }
};
