import styled from "styled-components";
import Link from "@nib-components/link";

export const LinkButton = styled(Link).attrs({ component: "button" })`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;
