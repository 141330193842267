// Replaces authGatewayProxy as we now call Auth0 directly, instead of through a lambda.
import axios from "axios";
import { AppConfiguration } from "../ConfigProvider";
import {
  connectionTypeForContact,
  isPhoneNumber,
  isEmail,
  ensurePlus61Format,
  stripSpaces,
} from "./contactUtilities";

const WEBAUTH_SEND_TYPE = "code";

export const passwordlessStartDirect = async (contact: string, config: AppConfiguration) => {
  const noWhitespaceContact = stripSpaces(contact);
  const { clientID, domain } = config;
  const data = {
    client_id: clientID,
    connection: connectionTypeForContact(noWhitespaceContact),
    send: WEBAUTH_SEND_TYPE,
    email: isEmail(noWhitespaceContact) ? noWhitespaceContact : undefined,
    phone_number: isPhoneNumber(noWhitespaceContact)
      ? ensurePlus61Format(noWhitespaceContact)
      : undefined,
  };

  const options = {
    method: "POST",
    url: `https://${domain}/passwordless/start`,
    data,
  };

  return axios(options);
};
