import * as Sentry from "@sentry/react";

const ENVIRONMENT = process.env.NODE_ENV ?? "development";

Sentry.init({
  dsn: "https://a99d0f557448eaccc3649dd8c9fcf277@o256546.ingest.sentry.io/4506137251020800",
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  environment: ENVIRONMENT,
  enabled: ENVIRONMENT === "production",
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});
