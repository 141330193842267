import { useStateValue } from "../../StateManagement";
import { useTealiumWrapper } from "../../util/TealiumWrapper";
import * as actionTypes from "./actionTypes";

export const useActionCreator = () => {
  const { dispatch } = useStateValue();
  const { tealiumTrack } = useTealiumWrapper();

  return {
    showContactUsComponent: () => {
      tealiumTrack("show_contact_us_component", {});

      dispatch({
        type: actionTypes.SHOW_CONTACT_US_COMPONENT,
      });
    },

    hideContactUsComponent: () => {
      tealiumTrack("hide_contact_us_component", {});

      dispatch({
        type: actionTypes.HIDE_CONTACT_US_COMPONENT,
      });
    },
  };
};
