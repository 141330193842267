import React, { JSX } from "react";
import Alert from "@nib-components/alert";
import Link from "@nib-components/link";
import { Link as ReactRouterLink } from "react-router-dom";
import { useConfig } from "../ConfigProvider";
import { ContactUsLink, ContactUsComponent } from "../ContactUs";
import { componentConstants } from "./dataConstants";

type AlertTypes = "error" | "success" | "info" | "warning";

export type AlertComponentProps = {
  title?: string;
  message?: string;
  type?: string;
};

export const AlertComponent = ({
  title,
  message,
  type,
}: AlertComponentProps): JSX.Element | null => {
  const config = useConfig();
  const constants = componentConstants(config.language);

  return type ? (
    <div data-testid="alert-component">
      <ContactUsComponent />
      <Alert fullWidth type={type === "blocked" ? "error" : (type as AlertTypes)} title={title}>
        <div data-testid="alert-component-content">
          {message}&nbsp;
          {type === "blocked" ? (
            <Link to="/" component={ReactRouterLink}>
              {constants.RESTART_AUTH_FLOW_LINK}
            </Link>
          ) : type !== "success" ? (
            <ContactUsLink toLower={true} id="ga-inlink-contact-us-from-alert" />
          ) : null}
        </div>
      </Alert>
    </div>
  ) : null;
};
