export const UPDATE_CONTACT = "UPDATE-CONTACT";
export const UPDATE_REMEMBER_CONTACT = "UPDATE_REMEMBER_CONTACT";
export const VALIDATE_FORM = "VALIDATE-FORM";
export const SHOW_ALERT = "SHOW-ALERT";
export const SHOW_ERROR = "SHOW-ERROR";
export const WRONG_CODE = "WRONG_CODE";
export const ACCOUNT_BLOCKED = "ACCOUNT_BLOCKED";
export const START_CONTACT_SENDING = "START_CONTACT_SENDING";
export const COMPLETE_CONTACT_SENDING = "COMPLETE_CONTACT_SENDING";
export const UPDATE_CONTACT_CODE = "UPDATE-CONTACT-CODE";

export const OTP_SHOW_ALERT = "SHOW-OTP-ALERT";
export const VALIDATE_OTP_FORM = "VALIDATE-OTP-FORM";
export const OTP_SHOW_ERROR = "SHOW-OTP-ERROR";
export const OTP_START_VERIFICATION = "START_CONTACT_VERIFICATION";
export const OTP_COMPLETE_VERIFICATION = "COMPLETE_CONTACT_VERIFICATION";

export const CLEAR_ALERTS = "CLEAR-ALERTS";

export const UPDATE_MEMBER_NUMBER = "UPDATE-MEMBER-NUMBER";
export const MEMBER_NUMBER_VALIDATE_INPUT = "MEMBER-NUMBER-VALIDATE-INPUT";

export const SET_CONTACT_TYPE = "SET-CONTACT-TYPE";
