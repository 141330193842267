import { AppConfiguration } from "../ConfigProvider";
import { emailRegex } from "./commonUtils";

const phoneNumberRegex = /^(\+61|0)\s*(4|5)(\s*\d){8}$/;
const codeRegex = /^\d{6}$/;
const numberRegex = /^\d{8}$/;

const validateContact = (contact: string, config: AppConfiguration): boolean => {
  const validators = [emailRegex];
  if (
    config.featureFlags?.enablePhoneNumberAuth &&
    !config.featureFlags.enablePhoneNumberAuth.isOff()
  ) {
    validators.push(phoneNumberRegex);
  }
  const validContact = validators.reduce((valid, validator) => {
    return valid || validator.test(contact);
  }, false);
  return validContact;
};

const validateCode = (code: string): boolean => {
  if (codeRegex.test(code)) {
    return true;
  }

  return false;
};

const validateMemberNumber = (memberNumber: string): boolean => {
  if (numberRegex.test(memberNumber)) {
    return true;
  }

  return false;
};

export { validateContact, validateCode, validateMemberNumber };
