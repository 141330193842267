import React, { JSX } from "react";
import { Box } from "@nib/layout";

type DisplayBorderProps = {
  children: JSX.Element | JSX.Element[];
  requireShadow?: boolean;
};

export const DisplayBorder = ({
  children,
  requireShadow = true,
}: DisplayBorderProps): JSX.Element => (
  <Box
    background="white"
    borderRadius="standard"
    width={"100%"}
    padding={{ xs: 4, md: 7 }}
    boxShadow={{ xs: "none", md: requireShadow ? "standard" : "none" }}
  >
    {children}
  </Box>
);
