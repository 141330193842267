import { emailRegex } from "./commonUtils";

export function isEmail(contact: string): boolean {
  if (emailRegex.test(contact)) {
    return true;
  }
  return false;
}
export function isPhoneNumber(contact: string): boolean {
  const phoneNumberRegex = /^(\+61|0)(4|5)\d{8}$/;
  if (phoneNumberRegex.test(contact)) {
    return true;
  }
  return false;
}
export function connectionTypeForContact(contact: string): string {
  // These need to be in a shared location
  if (isEmail(contact)) {
    return "email";
  }
  if (isPhoneNumber(contact)) {
    return "sms";
  }
  return "";
}

export function ensurePlus61Format(contact: string): string {
  if (isPhoneNumber(contact) && contact[0] === "0") {
    return `+61${contact.slice(1, contact.length)}`;
  }
  return contact;
}

export function stripSpaces(contact: string): string {
  return contact.replace(/ /g, ""); // replace all spaces with nothing
}

export const skipMemberNumberCheck = (brand) => {
  switch (brand) {
    case "nib-health-services":
      return true;
    default:
      return false;
  }
};
