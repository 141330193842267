import React, { createContext, useReducer, useContext, JSX } from "react";
import { useRememberState } from "./LocalStorageService";
import { reducer } from "./reducer";
import { State } from "../initialState";

export const GlobalStateContext = createContext(
  {} as {
    state: State;
    dispatch: React.Dispatch<any>;
  },
);

export const GlobalStateProvider = ({
  initialState,
  children,
}: {
  initialState: State;
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      <WithRememberState>{children}</WithRememberState>
    </GlobalStateContext.Provider>
  );
};

const WithRememberState = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  useRememberState();
  return <>{children}</>;
};

export const useStateValue = () => useContext(GlobalStateContext);
