import React, { JSX } from "react";
import TextBox from "@nib-components/textbox";
import styled from "styled-components";
import { colorLightest } from "@nib-components/theme";
import { FormControl } from "@nib-components/form-control";

const NoSpinnerBox = styled(TextBox)`
  background-color: ${colorLightest};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const InputBox = NoSpinnerBox;

type TextBoxComponentProps = {
  id?: string;
  name?: string;
  valid?: boolean;
  validated?: boolean;
  label?: string;
  error?: string;
  help?: any;
  dataTestid?: any;
  onChange?: any;
  onBlur?: any;
  value?: any;
  autoFocus?: any;
  disabled?: boolean;
  [x: string]: any;
};

export const TextBoxComponent = ({
  id,
  name,
  valid,
  validated,
  label = "",
  error,
  help,
  dataTestid,
  onChange,
  onBlur,
  value,
  autoFocus,
  disabled,
  ...otherTextBoxProps
}: TextBoxComponentProps): JSX.Element => {
  return (
    <div data-testid="textbox-component">
      <FormControl
        id={id}
        name={name}
        valid={valid}
        validated={validated}
        help={help}
        label={label}
        error={error}
        disabled={disabled}
        width={30}
      >
        <InputBox
          data-testid={dataTestid}
          onChange={onChange}
          value={value}
          onBlur={(e) => onBlur(e)}
          autoFocus={autoFocus}
          {...otherTextBoxProps}
        />
      </FormControl>
    </div>
  );
};
