import React, { JSX } from "react";
import Theme, {
  nib,
  gu,
  gu as cignagu,
  suncorp,
  apia,
  colorLightest,
  ing,
  priceline,
  aami,
  qantas,
  cota,
  nsi,
  australianSeniors,
  real,
} from "@nib-components/theme";
import { NibFonts } from "@nib/fonts";
import Grid from "styled-components-grid";
import breakpoint from "styled-components-breakpoint";
import styled from "styled-components";
import { Container } from "@nib/layout";

const Colordiv = styled.div`
  ${breakpoint("md")`
    background-color: ${colorLightest};
  `}
  background-image:${(props) => (props.isProd ? "" : `url(${props.assetBaseUrl}/kaos-badge.png)`)};
  background-repeat: no-repeat;
  background-size: 42px;
  background-position: center top;
  border-top: ${(props) => (props.isProd ? "" : "4px solid #F00")};
`;

type LoginPageContainerProps = {
  children?: JSX.Element | JSX.Element[];
  brand: string;
  isProd?: boolean;
  assetBaseUrl?: string;
  padding?: any;
};

const themes = {
  nib,
  "nib-iap": nib,
  "nib-health-services": nib,
  gu,
  cignagu,
  suncorp,
  apia,
  ing,
  iap: nib,
  priceline,
  aami,
  qantas,
  "nib-agent-travel": nib,
  nibau: nib,
  cota,
  ns: nsi,
  qan: qantas,
  nibnz: nib,
  real,
  australianSeniors,
};

export const LoginPageContainer = ({
  children,
  brand,
  isProd,
  assetBaseUrl,
  padding = 0,
}: LoginPageContainerProps): JSX.Element => (
  <div data-testid="login-container-component">
    <Theme theme={themes[brand]}>
      <NibFonts />
      <Colordiv data-testid="colored-background-div" isProd={isProd} assetBaseUrl={assetBaseUrl}>
        <Container>
          <Grid halign="center">
            <Grid.Unit size={padding}>{children}</Grid.Unit>
          </Grid>
        </Container>
      </Colordiv>
    </Theme>
  </div>
);
