import React, { JSX } from "react";
import Checkbox from "@nib-components/checkbox";
import { FormControl } from "@nib-components/form-control";

type CheckboxComponentProps = {
  id?: string;
  name?: string;
  label?: string;
  text?: string;
  error?: string;
  help?: any;
  dataTestid?: any;
  onChange?: any;
  disabled?: boolean;
  checked: boolean;
};

export const CheckboxComponent = (props: CheckboxComponentProps): JSX.Element => {
  const {
    id,
    name,
    label = "",
    text = "",
    error,
    help,
    dataTestid,
    onChange,
    disabled,
    checked,
  } = props;

  return (
    <div data-testid="checkbox-component">
      <FormControl
        id={id}
        name={name}
        help={help}
        label={label}
        error={error}
        disabled={disabled}
        width={30}
      >
        <Checkbox data-testid={dataTestid} label={text} checked={checked} onChange={onChange} />
      </FormControl>
    </div>
  );
};
