import React, { JSX } from "react";
import { PageTemplate } from "../components";
import { useConfig } from "../ConfigProvider";
import { Box, Columns, Column } from "@nib/layout";
import { HeaderPanel } from "./HeaderPanel";
import ServicesNotificationComponent from "../components/ServicesNotificationComponent";

export function AdvertisingPageTemplate({
  loginPanel,
  advertisingPanel,
}: {
  loginPanel: JSX.Element | JSX.Element[];
  advertisingPanel: JSX.Element | JSX.Element[];
}): JSX.Element {
  const config = useConfig();
  const loginWithPanelsPaddingSizes = {
    md: 0.8,
    lg: 0.9,
  };
  return (
    <PageTemplate
      brand={config.brand}
      isProd={config.isProd}
      assetBaseUrl={config.assetBaseUrl}
      padding={loginWithPanelsPaddingSizes}
    >
      <HeaderPanel />
      <ServicesNotificationComponent />
      <Columns verticalAlign="stretch" space={2} collapseBelow="xl">
        <Column flex width="2/5">
          <Box
            background="white"
            width="100%"
            height="100%"
            borderRadius="standard"
            display="flex"
            alignItems="center"
          >
            {loginPanel}
          </Box>
        </Column>

        <Column flex>{advertisingPanel}</Column>
      </Columns>
    </PageTemplate>
  );
}
