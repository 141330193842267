import { createContext, useContext, useEffect, useState } from "react";
import { ServicesNotification } from "./servicesNotificationApi";
import React from "react";
import { useConfig } from "../ConfigProvider";

const ServicesNotificationContext = createContext<{
  servicesNotification: ServicesNotification | null;
}>({ servicesNotification: null });

export const ServicesNotificationProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [servicesNotification, setServicesNotification] = useState<ServicesNotification | null>(
    null,
  );
  const config = useConfig();

  useEffect(() => {
    const callApi = async () => {
      const response = await config.getServicesNotification(config.brand);
      setServicesNotification(response);
    };

    callApi();
  }, [config.brand, config.getServicesNotification]);

  return (
    <ServicesNotificationContext.Provider value={{ servicesNotification }}>
      {children}
    </ServicesNotificationContext.Provider>
  );
};

export const useServicesNotification = () => {
  const context = useContext(ServicesNotificationContext);
  return context.servicesNotification;
};
