import React, { JSX } from "react";
import Modal from "@nib-components/modal";
import breakpoint from "styled-components-breakpoint";
import { useActionCreator } from "./actions/actionCreator";
import styled from "styled-components";
import { contactUsConstants } from "./dataConstants";
import { useConfig } from "../ConfigProvider";
import { useStateValue } from "../StateManagement";
import { ContactPhoneNumberDisplay } from "./ContactPhoneNumberDisplay";

//If modal height is greater than the view height, then this will cause issues
const DockModalAtBottom = styled(Modal)`
  ${breakpoint("xs", "md")`
    align-items: flex-end;
  `}
`;

export const ContactUsComponent = (): JSX.Element => {
  const actionCreator = useActionCreator();
  const config = useConfig();
  const constants = contactUsConstants(config?.language, config?.brand);
  const { state } = useStateValue();
  return (
    <DockModalAtBottom
      data-testid="contact-us-component"
      title={constants.contactUsModalTitle}
      visible={state.contactUsFlow.showComponent}
      onClose={actionCreator.hideContactUsComponent}
    >
      {ContactPhoneNumberDisplay()}
    </DockModalAtBottom>
  );
};
