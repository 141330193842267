type EnablePhoneNumberAuthStatesType = {
  on: string;
  off: string;
  silent: string;
};

const enablePhoneNumberAuthStates: EnablePhoneNumberAuthStatesType = {
  on: "on",
  off: "off",
  silent: "silent",
};

export class EnablePhoneNumberAuthFeatureFlag {
  setting: string;
  constructor(setting: string) {
    this.setting = setting.toLowerCase();
  }
  isOn() {
    return this.setting === enablePhoneNumberAuthStates.on;
  }
  isOff() {
    return this.setting === enablePhoneNumberAuthStates.off;
  }
  isSilent() {
    return this.setting === enablePhoneNumberAuthStates.silent;
  }
}
