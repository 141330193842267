const english = {
  RESTART_AUTH_FLOW_LINK: "Enter your mobile number or email again",
};

export const componentConstants = (language?: string) => {
  switch (language) {
    case "english":
      return english;
    default:
      return english;
  }
};
