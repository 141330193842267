const brandNamesToBrand = {
  nib: "nib",
  gu: "GU Health",
  cignagu: "Cigna Australia by GU Health",
  apia: "Apia",
  suncorp: "Suncorp",
  ing: "ING Health Insurance",
  priceline: "Priceline Health Insurance",
  qantas: "Qantas Health Insurance",
  aami: "AAMI Health Insurance",
  nibau: "nib",
  cota: "COTA",
  ns: "National Seniors",
  qan: "Qantas",
  nibnz: "nib Health Insurance",
  real: "Real Health Insurance",
  australianseniors: "Seniors Health Insurance",
};

/** Used for the need help URL */
const brandDomainsToBrand = {
  kaos: {
    nib: "www.nib-cf-test.com",
    gu: "guhealth-test.com",
    cignagu: "cigna.guhealth-test.com",
    apia: "health-test.apia.com.au",
    suncorp: "health-test.suncorp.com.au",
    ing: "health-test.ing.com.au",
    priceline: "health-test.priceline.com.au",
    aami: "health-test.aami.com.au",
    qantas: "health.stage.insurance.qantas.com",
    nibnz: "my.nibnz-cf-test.com",
    real: "health-test.realinsurance.com.au",
    australianseniors: "health-test.seniors.com.au",
  },
  prod: {
    nib: "www.nib.com.au",
    gu: "guhealth.com.au",
    cignagu: "cigna.guhealth.com.au",
    apia: "health.apia.com.au",
    aami: "health.aami.com.au",
    suncorp: "health.suncorp.com.au",
    ing: "health.ing.com.au",
    priceline: "health.priceline.com.au",
    nibnz: "my.nib.co.nz",
    real: "health.realinsurance.com.au",
    australianseniors: "health.seniors.com.au",
  },
};

export const brandNameForBrand = (brand: string): string => {
  return brandNamesToBrand[brand.toLowerCase()];
};

export const brandDomainsForBrand = (brand: string, stage: string): string => {
  return brandDomainsToBrand[stage.toLowerCase()][brand.toLowerCase()];
};

const brandsWithEmailLoginOnly = ["nib"];

export const isEmailOnlyBrand = (brand: string) => brandsWithEmailLoginOnly.includes(brand);
