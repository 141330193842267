type ConfigResponse = {
  statusCode: number;
  status: string;
  data: {
    config: {
      notifications: {
        alert: ServicesNotification;
      };
    };
  };
};

export type ServicesNotification = {
  title: string;
  details: string;
  level: AlertLevel;
  icon: AlertIcon;
};

// these are values set in the contentful model definition
export type AlertLevel = "allServicesOutage" | "serviceOutage" | "warning" | "notice" | "info";
export type AlertIcon = "outage" | "upgrade";

const brandMap = {
  nib: "nib",
  iap: "nib",
  gu: "gu",
  cignagu: "cigna",
  apia: "apia",
  suncorp: "suncorp",
  ing: "ing",
  "nib-health-services": "greenpass",
  priceline: "priceline",
  aami: "aami",
  qantas: "qantas",
  nibau: "nib-agent-travel",
  cota: "nib-agent-cota",
  ns: "nib-agent-nsi",
  qan: "nib-agent-qantas",
  real: "real",
  australianSeniors: "australianSeniors",
};

function gripBrandToConfigApiBrand(brand: string): string {
  const mappedBrand = brandMap[brand];
  if (!mappedBrand) {
    throw new Error(`Missing brand '${brand}' from the brandMap`);
  }

  return mappedBrand;
}

export type GetServicesNotificationFunction = (
  brand: string,
) => Promise<ServicesNotification | null>;

export function getServicesNotificationApiFunction(
  configUrl: string,
): GetServicesNotificationFunction {
  return async function getServicesNotification(
    brand: string,
  ): Promise<ServicesNotification | null> {
    brand = gripBrandToConfigApiBrand(brand);

    const endpoint = `${configUrl}/v1/config?brand=${brand}&app=GRIP&version=1.0.0`;
    const response = await fetch(endpoint);

    if (!response.ok) {
      return null;
    }

    const body = (await response.json()) as ConfigResponse;

    if (body.statusCode < 200 || body.statusCode >= 300) {
      return null;
    }

    return body?.data?.config?.notifications?.alert ?? null;
  };
}
