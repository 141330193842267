import { useTealium } from "@nib/tealium-universal-tag/react";
import TealiumWithGlobalData from "@nib/tealium-universal-tag/react/withGlobalData";
import { useCallback } from "react";

export type TealiumTrack = (eventName: string, eventData: Record<string, unknown>) => void;
export type TealiumPageView = (eventData: Record<string, unknown>) => void;

type TealiumWrapper = {
  tealiumTrack: TealiumTrack;
  tealiumPageView: TealiumPageView;
};

export const useTealiumWrapper = (): TealiumWrapper => {
  const tealiumInstance: TealiumWithGlobalData = useTealium();

  const tealiumTrack: TealiumTrack = useCallback(
    (eventName: string, eventData: Record<string, unknown>): void => {
      if (tealiumInstance && typeof tealiumInstance.track === "function") {
        tealiumInstance.track(eventName, eventData);
      }
    },
    [tealiumInstance],
  );

  const tealiumPageView: TealiumPageView = useCallback(
    (eventData: Record<string, unknown>): void => {
      if (tealiumInstance && typeof tealiumInstance.pageView === "function") {
        tealiumInstance.pageView(eventData);
      }
    },
    [tealiumInstance],
  );

  return {
    tealiumTrack,
    tealiumPageView,
  };
};
