import React, { JSX } from "react";
import { History } from "history";
import { useConfig } from "../ConfigProvider";
import { Login } from "../Login";
import { NibAppPanel } from "./NibAppPanel";
import { appNames } from "../util/appMapping";
import { BasicPageTemplate } from "./BasicPageTemplate";
import { AdvertisingPageTemplate } from "./AdvertisingPageTemplate";

const allowedMultiPanelApps = [appNames.nibOnlineServicesWeb, appNames.nibOnlineServicesWebTest];

type LoginPanelMasterProps = {
  history: History;
};

export function LoginPanelMaster({ history }: LoginPanelMasterProps): JSX.Element {
  const config = useConfig();
  return allowedMultiPanelApps.includes(config.currentApp ?? "") ? (
    <AdvertisingPageTemplate
      loginPanel={<Login history={history} isDefaultLogin={false} />}
      advertisingPanel={<NibAppPanel assetBaseUrl={config.assetBaseUrl} />}
    />
  ) : (
    <BasicPageTemplate>
      <Login history={history} />
    </BasicPageTemplate>
  );
}
