import { BaseAction } from "../StateManagement/reducer";
import * as actionTypes from "./actions/actionTypes";

export const contactUsReducer = (
  state: {
    showComponent: boolean;
  },
  action: BaseAction,
) => {
  switch (action.type) {
    case actionTypes.SHOW_CONTACT_US_COMPONENT:
      return {
        ...state,
        showComponent: true,
      };
    case actionTypes.HIDE_CONTACT_US_COMPONENT:
      return {
        ...state,
        showComponent: false,
      };
    default:
      return state;
  }
};
