import { loginReducer } from "../Login/reducer/loginReducer";
import { contactUsReducer } from "../ContactUs/reducer";
import { State } from "../initialState";

export interface BaseAction {
  type: string;
}

export const reducer = (state: State, action: BaseAction): State => {
  return {
    login: loginReducer(state.login, action),
    contactUsFlow: contactUsReducer(state.contactUsFlow, action),
  };
};
