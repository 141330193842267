import React, { JSX } from "react";
import { LinkButton } from "../components/LinkButtonComponent";
import { useActionCreator } from "./actions/actionCreator";
import { contactUsConstants } from "./dataConstants";
import { useConfig } from "../ConfigProvider";

type ContactUsLinkProps = {
  id: string;
  toLower?: boolean;
};

export const ContactUsLink = ({ id, toLower }: ContactUsLinkProps): JSX.Element => {
  const action = useActionCreator();
  const config = useConfig();
  const constants = contactUsConstants(config?.language, config?.brand);

  return (
    <LinkButton id={id} onClick={action.showContactUsComponent}>
      {toLower ? constants.contactUsLinkText.toLowerCase() + "." : constants.contactUsLinkText}
    </LinkButton>
  );
};
