import React, { JSX } from "react";
import styled from "styled-components";
import Form from "@nib-components/form";
import { colorWhite } from "@nib-components/theme";
import { p } from "styled-components-spacing";

const NoBorder = styled(Form)`
  border: 0px;
  width: 100%;
  background: ${colorWhite};
  ${p(0)}
`;

type NoBorderFormProps = {
  children: any;
  [x: string]: any;
};

export const NoBorderForm = ({ children, ...props }: NoBorderFormProps): JSX.Element => (
  <NoBorder noValidate={true} {...props}>
    {children}
  </NoBorder>
);
