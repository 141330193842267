import React, { JSX } from "react";
import { TealiumProvider } from "@nib/tealium-universal-tag/react";
import { Route, HashRouter as Router } from "react-router-dom";
import { ConfigProvider } from "./ConfigProvider";
import { OTP } from "./Login";
import { GlobalStateProvider } from "./StateManagement";
import { restoreWithDefault } from "./StateManagement/LocalStorageService";
import { State, initialState } from "./initialState";
import { LoginPanelMaster } from "./panels";
import { account, profile, environment } from "./tealiumConfig";
import { ServicesNotificationProvider } from "./util/ServicesNotificationProvider";

const initialRestoredState = restoreWithDefault(initialState) as State;

const App = (): JSX.Element => {
  return (
    <Router>
      <GlobalStateProvider initialState={initialRestoredState}>
        <TealiumProvider
          account={account}
          profile={profile}
          debug={false}
          environment={environment}
        >
          <ConfigProvider>
            <ServicesNotificationProvider>
              <Route exact path="/" component={LoginPanelMaster} />
              <Route path="/otp" component={OTP} />
            </ServicesNotificationProvider>
          </ConfigProvider>
        </TealiumProvider>
      </GlobalStateProvider>
    </Router>
  );
};

export default App;
