import * as Sentry from "@sentry/react";
import { useConfig } from "../../ConfigProvider";
import { useStateValue } from "../../StateManagement";
import { doAuth0PasswordlessLogin, responseTypes } from "../../util/Auth0";
import { useTealiumWrapper } from "../../util/TealiumWrapper";
import { validateCode } from "../../util/fieldValidator";
import { passwordlessFlowConstants } from "../dataConstants";
import * as actionTypes from "./actionTypes";

export const useActionCreator = () => {
  const { state, dispatch } = useStateValue();
  const config = useConfig();
  const constants = passwordlessFlowConstants(config);
  const { tealiumTrack } = useTealiumWrapper();

  const displayAlert = ({ type, title, message }) => {
    dispatch({
      type: actionTypes.OTP_SHOW_ALERT,
      alertType: type,
      title,
      message,
    });
  };

  const clearAlerts = () => {
    dispatch({
      type: actionTypes.CLEAR_ALERTS,
    });
  };

  return {
    clearAlerts: () => {
      clearAlerts();
    },

    updateCode: (code) => {
      //removing everything but the numbers from the code
      code = code.replace(/\D/g, "");
      clearAlerts();
      dispatch({
        type: actionTypes.UPDATE_CONTACT_CODE,
        code,
        isValid: validateCode(code),
      });
    },

    validateForm: () => {
      dispatch({
        type: actionTypes.VALIDATE_OTP_FORM,
        hasBeenValidated: true,
        validationMessage:
          state.login.otp.code === "" || state.login.otp.isValid
            ? ""
            : constants.ENTER_CODE_VALIDATION_MESSAGE,
      });
    },

    completePasswordlessLogin: () => {
      const isValid = state.login.otp.isValid;
      const memberNumber = state.login.login.memberNumber.memberNumber;

      if (isValid) {
        tealiumTrack("login_successful", { contact: state.login.login.contact, memberNumber });
        dispatch({
          type: actionTypes.OTP_START_VERIFICATION,
          verifying: true,
        });

        return doAuth0PasswordlessLogin({
          config,
          code: state.login.otp.code,
          contact: state.login.login.contact,
          memberNumber,
        })
          .then((response) => {
            if (response.success) {
              dispatch({
                type: actionTypes.OTP_COMPLETE_VERIFICATION,
              });
              return;
            }
            switch (response.reason) {
              case responseTypes.badEmailCodeCombination:
                tealiumTrack("wrong_code_error", {
                  contact: state.login.login.contact,
                  memberNumber,
                });
                dispatch({
                  type: actionTypes.WRONG_CODE,
                  message: constants.ENTER_CODE_WRONG_CODE_MESSAGE,
                });
                return;
              case responseTypes.accountLocked:
                tealiumTrack("account_blocked_error", {
                  contact: state.login.login.contact,
                  memberNumber,
                });
                dispatch({
                  type: actionTypes.ACCOUNT_BLOCKED,
                  alertType: "blocked",
                  title: constants.ENTER_CODE_TOO_MANY_ATTEMPTS_ALERT_TITLE,
                  message: constants.ENTER_CODE_TOO_MANY_ATTEMPTS_ALERT_MESSAGE,
                });
                return;
              case responseTypes.unknownError:
              default:
                tealiumTrack("system_error", { contact: state.login.login.contact, memberNumber });
                displayAlert({
                  type: "error",
                  title: constants.SYSTEM_ERROR_ALERT_TITLE,
                  message: constants.SYSTEM_ERROR_ALERT_MESSAGE,
                });
                return;
            }
          })
          .catch((err) => {
            tealiumTrack("system_error", { contact: state.login.login.contact, memberNumber });
            Sentry.captureException(
              `$$$-doAuth0PasswordlessLogin-exception: ${JSON.stringify(err)}`,
            );
            displayAlert({
              type: "error",
              title: constants.SYSTEM_ERROR_ALERT_TITLE,
              message: constants.SYSTEM_ERROR_ALERT_MESSAGE,
            });
          });
      } else {
        tealiumTrack("invalid_otp", { contact: state.login.login.contact, memberNumber });
        return Promise.resolve();
      }
    },
  };
};
