import { useEffect } from "react";

export const useOptimizely = (optimizelyUrl?: string) => {
  useEffect(() => {
    if (optimizelyUrl) {
      const script = document.createElement("script");

      script.src = optimizelyUrl;

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [optimizelyUrl]);
};
