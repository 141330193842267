import { useEffect, useRef } from "react";
import { ContactType, State } from "../initialState";
import { useStateValue } from "./GlobalState";
const localStateKey = "authState";

const getSavedState = (): State | undefined => {
  try {
    const storedValue = localStorage.getItem(localStateKey);
    if (storedValue) {
      return loadLoginState(storedValue);
    }
    return undefined;
  } catch {
    return undefined;
  }
};

const loadLoginState = (storedValue: string): State | undefined => {
  const state = JSON.parse(storedValue) as State | undefined;
  if (!state) {
    return undefined;
  }
  // do not set hasBeenValidated if the contact is not set, so that
  //  the error box isn't visible on page load from an old invalid state
  const loginState = state?.login?.login;
  if (loginState && !loginState.contact) {
    loginState.hasBeenValidated = false;
  }

  // If the previous contact type was phone, don't restore the contact.
  // We want to encourage users to use email.
  if (loginState && loginState.previousContactType === ContactType.PHONE) {
    return undefined;
  }

  // rememberContact was added in GRIP-1453. Before this it didn't exist and contact was always saved.
  // So if login has been stored previously but doesn't include rememberContact set rememberContact to true.
  if (loginState && loginState.rememberContact === undefined) {
    loginState.rememberContact = true;
  }

  return state;
};

export const restoreWithDefault = (defaultState: State): State | undefined => {
  const stored = getSavedState();
  // recurse through the default state and replace defaults with saved properties.
  return reconstituteState(stored, defaultState);
};

const reconstituteState = (
  stored: State | undefined,
  defaultState: State | undefined,
): State | undefined => {
  if (!stored) {
    return defaultState;
  }
  const reconstitutedState = {} as State;
  for (let key in defaultState) {
    if (typeof defaultState[key] === "object") {
      reconstitutedState[key] = reconstituteState(stored[key], defaultState[key]);
    } else {
      reconstitutedState[key] = stored[key] !== undefined ? stored[key] : defaultState[key];
    }
  }
  return reconstitutedState;
};

function setLocalStorage(valueToStore: Partial<State>) {
  localStorage.setItem(localStateKey, JSON.stringify(valueToStore));
}

export const useRememberState = () => {
  const { state } = useStateValue();
  const loginState = state?.login?.login;

  const isInitialMount = useRef(true);
  const objectToPersist = removePropertiesNotSpecified(propertiesToStore, state);

  // Don't persist login details if rememberContact isn't checked
  if (loginState?.rememberContact !== undefined && !loginState.rememberContact) {
    const loginToPersist = objectToPersist?.login?.login;

    if (loginToPersist?.contact !== undefined) {
      loginToPersist.contact = "";
      loginToPersist.isValid = true;
      loginToPersist.hasBeenValidated = false;
      loginToPersist.displayMemberNumberForm = false;
    }

    if (loginToPersist?.memberNumber?.memberNumber !== undefined) {
      loginToPersist.memberNumber.memberNumber = "";
      loginToPersist.memberNumber.isValid = true;
      loginToPersist.memberNumber.hasBeenValidated = false;
    }
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setLocalStorage(objectToPersist);
    }
  }, [objectToPersist]);
};

const removePropertiesNotSpecified = (propertiesToStore, stateToStore) => {
  const filteredState: Partial<State> = {};

  if (!stateToStore) return filteredState;

  for (let key in propertiesToStore) {
    if (typeof propertiesToStore[key] === "object") {
      filteredState[key] = removePropertiesNotSpecified(propertiesToStore[key], stateToStore[key]);
    } else {
      if (stateToStore[key] !== undefined) {
        filteredState[key] = stateToStore[key];
      }
    }
  }
  return filteredState;
};

const propertiesToStore = {
  login: {
    login: {
      contact: true,
      rememberContact: true,
      previousContactType: true,
      displayMemberNumberForm: true,
      isValid: true,
      hasBeenValidated: true,
      lastPasswordlessStartSuccessResultTime: true,
      memberNumber: {
        memberNumber: true,
        isValid: true,
        hasBeenValidated: true,
      },
    },
  },
};
