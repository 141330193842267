type PanelConstants = {
  NIB_APP_PANEL_HEADING: string;
  NIB_APP_PANEL_HEADING_SMALL: string;
  NIB_APP_PANEL_COPY: string;
};

export function panelConstants(language?: string): PanelConstants {
  switch (language) {
    default:
    case "english":
      return {
        NIB_APP_PANEL_HEADING: "Manage your health on the go",
        NIB_APP_PANEL_HEADING_SMALL: "Download the nib App",
        NIB_APP_PANEL_COPY:
          "Access your membership wherever you are with the nib App. Snap a photo of your receipt to claim, check your inclusions, benefits and rewards on the go, and more.",
      };
  }
}
