import { AppConfiguration } from "../../ConfigProvider";
import { State } from "../../initialState";

export const shouldShowPhoneNumberInfoBox = (state: State, config: AppConfiguration) => {
  if (config?.brand !== "nib") {
    return false;
  }

  const phoneFeature = config?.featureFlags?.enablePhoneNumberAuth;

  if (phoneFeature?.isOff()) {
    return false;
  }

  return state?.login?.login?.displayMemberNumberForm;
};
