import Accordion from "@nib-components/accordion";
import { colorInfo } from "@nib-components/theme";
import styled from "styled-components";
import InfoBox from "@nib/info-box";
import { p } from "styled-components-spacing";
import React from "react";
import Copy from "@nib-components/copy";

const PhoneNumberInfoBox = () => {
  return (
    <InfoBoxContainer backgroundColor="white" iconAndBorderColor={colorInfo} withIcon={false}>
      <Accordion borderTop={false} borderBottom={false} expandedIndex={0}>
        <Accordion.Item title="We're upgrading our login experience on 2nd of December 2024">
          <Copy>
            To support new safety features, we're removing the mobile number login on 2nd of
            December 2024. You can log in using the email address that's linked to your policy.
          </Copy>
        </Accordion.Item>
      </Accordion>
    </InfoBoxContainer>
  );
};

const InfoBoxContainer = styled(InfoBox)`
  ${p(3)};
  h4 {
    font-size: 16px;
  }
`;

export default PhoneNumberInfoBox;
