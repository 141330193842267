import React, { JSX } from "react";
import { PageTemplate } from "../components";
import { useConfig } from "../ConfigProvider";

export function BasicPageTemplate({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element {
  const config = useConfig();
  const defaultPaddingSizes = {
    sm: 0.8,
    md: 0.5,
    lg: 0.4,
  };
  return (
    <PageTemplate
      brand={config.brand}
      isProd={config.isProd}
      assetBaseUrl={config.assetBaseUrl}
      padding={defaultPaddingSizes}
    >
      {children}
    </PageTemplate>
  );
}
